import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@core/helpers/params-generator';
import moment from 'moment';
import { Observable } from 'rxjs';

import { IResponse } from '../models/response.model';

@Injectable({
  providedIn: 'root',
})
export class CheckinOutService {
  constructor(private _http: HttpClient) {}

  public loadCheckinOut(
    propertiesIds: number[],
    date_from,
  ): Observable<IResponse> {
    return this._http.get<IResponse>(
      `reservations/checkin_checkout_day/${date_from}?${generateParamArray(
        'property_id',
        propertiesIds,
      )}`,
    );
  }
}
